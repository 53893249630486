<style lang="scss">
@import "~@/assets/css/var";
.bid-compare {
  padding-top: 10px;
  .el-badge {
    margin-right: 25px;
    margin-bottom: 10px;
  }
  .el-badge__content.is-fixed {
    z-index: 1;
    font-size: 8px;
  }
  .el-tag {
    color: #fff;
    line-height: 24px;
    height: 25px;
    .el-tag__close {
      color: $red;
      &:hover {
        background-color: #fff;
      }
    }
  }
  &__select {
    .el-select-dropdown__item {
      font-size: 12px;
      padding: 5px 13px;
      height: unset;
      white-space: normal;
      max-width: 630px;
      line-height: 1.5;
    }
  }
  .el-select {
    .el-select__caret:first-child::before {
      content: "\e6e1";
    }
    .is-focus {
      .el-select__caret:first-child {
        transform: rotateZ(0deg);
      }
    }
  }
}
</style>
<template>
    <div class="bid-compare">
        <div>
            <el-badge v-for="(item, i) in conditionList" :key="item.id" :hidden="!item[fieldShow]" :value="item[fieldShow]">
                <el-tag :color="colors[i]" :closable="i !== 0" @close="handleClose(i)">{{item.winningProvince ? `${item.winningProvince}-` : ''}}{{item.showTxt}}</el-tag>
            </el-badge>
        </div>
        <div style="padding-top: 10px;">新增价格趋势对比：
            <el-select ref="select" popper-class="bid-compare__select" v-model="add.model" value-key="id" filterable remote placeholder="选择数据对比" :loading="add.loading"
                       :remote-method="searchFunc" @change="modelChange" style="width: 300px">
                <el-option v-for="item in add.list" :key="item.id" :value="item">
                    <div class="flex">
                        <div class="flex-item">{{`${item.compName}-${item.comName || ''}${item.unifySpec || ''}(转换比：${item.conversionRatio || ''})`}}</div>
                        <el-tag v-if="item.winProvinceNumber" size="mini" style="margin-left: 10px;">{{item.winProvinceNumber}}</el-tag>
                    </div>
                </el-option>
            </el-select>

            <el-radio-group v-model="priceType" style="margin-left: 15px;" @change="renderCanvas">
                <el-radio-button :label="false">包装价</el-radio-button>
                <el-radio-button :label="true">制剂价</el-radio-button>
            </el-radio-group>

            <el-radio-group v-if="hasShowType" v-model="showType" style="margin-left: 15px;" @change="renderCanvas">
                <el-radio-button :label="0">全部</el-radio-button>
                <el-radio-button :label="1" title="表示某一个时间点的具体价格">实线</el-radio-button>
                <el-radio-button :label="2" title="表示某一个时间点的全国价格加权平均值">均线</el-radio-button>
            </el-radio-group>
        </div>
        <div id="canvas-bid" style="height: 450px"></div>
    </div>
</template>

<script>
export default {
    props: ['row', 'type'],
    data() {
        return {
            selectApi: '',
            api: '',
            field: '', // 读接口的时候传的字段名
            fieldShow: '', // 暴涨暴跌显示的字段

            colors: [
                '#058DC7',
                '#50B432',
                '#C71585',
                '#FF9655',
                '#a79c5f',
                '#1c9d6d'],

            conditionList: [],
            conditionMap: {},
            conditionSort: {},

            add: {
                model: {},
                loading: false,
                list: []
            },

            priceType: false,

            // 显示实线还是均线, 0:全部, 1:实线, 2:均线
            showType: 0,
            hasShowType: false,

            xData: [],
            canvasData: [],
            toolMap: {},
        }
    },
    created() {
        switch (this.type) {
            case 'drug':
                this.api = 'indexDrug/aimBaseDrugsWinning/windingPriceTrend'
                this.field = 'aimBaseDrugsWinningId'
                this.fieldShow = 'priceState'
                this.selectApi = 'indexDrug/aimBaseDrugsWinning/queryWinningPage'
                break
            case 'corp':
                this.api = 'indexComp/aimBaseDrugsWinning/windingPriceTrend'
                this.field = 'aimBaseDrugsWinningId'
                this.fieldShow = 'priceState'
                this.selectApi = 'indexComp/aimBaseDrugsWinning/queryWinningPage'
                break
            case 'common':
                this.api = 'mainf/mainfDrugWinningInformations/windingPriceTrend'
                this.field = 'id'
                this.fieldShow = 'priceState'
                this.selectApi = 'mainf/mainfDrugWinningInformations/queryMainfDrugWinningInformationsPage'
                break
            case 'bidCountry':
                this.api = 'mainf/aimBaseDrugsWinning/windingPriceTrend'
                this.field = 'aimBaseDrugsWinningId'
                this.fieldShow = 'priceState'
                this.selectApi = 'indexDrug/aimBaseDrugsWinning/queryWinningPage'
                break
            case 'bidProvince':
                this.api = 'mainf/aimBaseDrugsProvinceWinning/windingPriceTrend'
                this.field = 'id'
                this.fieldShow = 'priceState'
                this.selectApi = 'indexDrug/aimBaseDrugsWinning/queryWinningPage'
                break
        }

        if (Object.prototype.toString.call(this.row) === '[object Object]') {
            const row = this.row
            row.showTxt = this.getTxt(row)
            this.conditionList.push(row)
            this.searchFunc(row.comName)
        } else {
            this.conditionList = this.conditionList.concat(this.row.map(item => {
                item.showTxt = this.getTxt(item)
                return item
            }))
            this.searchFunc(this.row[0].comName)
        }

        this.getData()
    },
    methods: {
        searchFunc(keyword = '') {
            this.add.loading = true
            this.$api.post(this.selectApi, {
                page: {
                    pageNo: 1,
                    pageSize: 30,
                },
                cndCluster:{
                    op: 'or',
                    cnds:[
                        {name: 'comName',op: 'like', value: keyword},
                        {name: 'compName',op: 'like', value: keyword},
                    ]
                },
            })
                .then(res => {
                    this.add.list = res.data.records.map(item => {
                        item.showTxt = this.getTxt(item)
                        return item
                    })
                })
                .finally(() => {
                    this.add.loading = false
                })
        },
        modelChange() {
            if (this.conditionList.map(item => item.id).includes(this.add.model.id)) {
                this.$store.commit('error', '该项已存在')
            } else {
                // 不允许超过6条
                if (this.conditionList.length >= 6) {
                    this.$store.commit('error', '最多只能添加6条噢')
                } else {
                    this.conditionList.push(this.add.model)
                    this.getData()
                }
            }
            this.add.model = {}

            this.$nextTick(() => {
                this.$refs.select.toggleMenu()
            })
        },
        handleClose(index) {
            this.conditionList.splice(index, 1)
            this.getData()
        },
        getTxt(form) {
            return `${form.compName}-${form.comName || ''}${form.unifySpec || ''}(转换比：${form.conversionRatio || ''})`
        },
        dealXData(list, xAxis) {
            const priceField = this.priceType ? 'unitPrice' : 'price'
            const obj = {}
            list.forEach(item => {
                obj[item.winningTime] = item
            })
            const arr = xAxis.map((item) => {
                if (obj[item]) {
                    if (obj[item].drugsOrigin) {
                    }
                    return obj[item][priceField] == 0 ? '' : obj[item][priceField]
                } else {
                    return ''
                }
            })

            return {
                data: arr,
            }

        },
        getData() {
            this.conditionMap = {}
            this.conditionSort = {}
            this.conditionList.forEach((item, index) => {
                this.$set(this.conditionMap, item.id, item)
                this.$set(this.conditionSort, item.id, index)
            })
            this.$api.get(this.api, {[this.field]: this.conditionList.map(item => item.id).join()})
                .then(res => {
                    const list = res.data
                        .map(item => {
                            // 计算制剂价
                            const conversionRatio = this.conditionMap[item.id].conversionRatio || 0
                            if (item.data && item.data.length > 0) {
                                item.data = item.data.map(c => {
                                    c.unitPrice = conversionRatio ? this.$help.accDiv(c.price, conversionRatio).toFixed(2) : 0
                                    return c
                                })
                            }
                            if (item.avgPrice && item.avgPrice.length > 0) {
                                this.hasShowType = true
                                item.avgPrice = item.avgPrice.map(c => {
                                    c.unitPrice = conversionRatio ? this.$help.accDiv(c.price, conversionRatio).toFixed(2) : 0
                                    return c
                                })
                            }

                            item.name = this.getTxt(this.conditionMap[item.id])
                            return item
                        })
                        .sort((a, b) => this.conditionSort[a.id] - this.conditionSort[b.id])
                    // 合并X轴
                    const xAxis = {}

                    const toolMap = {}
                    list.forEach(item => {
                        if (item.data && item.data.length > 0) {
                            item.data.forEach(row => {
                                xAxis[row.winningTime] = row.winningTime
                                toolMap[item.name + row.winningTime] = `${row.winningProvince ? `${row.winningProvince} ` : ''}${row.drugsOrigin || ''}`
                            })
                        }
                    })
                    this.toolMap = toolMap

                    this.xData = Object.keys(xAxis).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

                    this.canvasData = list

                    this.renderCanvas()
                })
        },
        renderCanvas() {
            const myChart = window.echarts.init(document.getElementById('canvas-bid'))
            myChart.clear()

            const toolMap = this.toolMap

            let colorList = []
            if (this.canvasData && this.canvasData.length > 0) {
                const series = []
                this.canvasData.forEach((item, i) => {

                    if (!this.showType || this.showType === 1) {
                        colorList.push(this.colors[i])
                        series.push(Object.assign({
                            type: 'line',
                            name: item.name + '-实线',
                            smooth: true,
                            // symbolSize: 0,
                            connectNulls: true,
                            lineStyle: {
                                type: 'solid',
                            }
                        }, this.dealXData(item.data, this.xData)))
                    }

                    if ((!this.showType || this.showType === 2) && item.avgPrice) {
                        colorList.push(this.colors[i])
                        series.push(Object.assign({
                            type: 'line',
                            name: item.name + '-均线',
                            // symbolSize: 0,
                            smooth: true,
                            connectNulls: true,
                            lineStyle: {
                                type: 'dashed',
                            }
                        }, this.dealXData(item.avgPrice, this.xData)))
                    }
                })
                myChart.setOption({
                    color: colorList,
                    tooltip: {
                        trigger: 'axis',
                        formatter(arr) {
                            return arr
                                .filter(item => item.value)
                                .map(item => {
                                    let drugsOrigin = toolMap[(item.seriesName + item.axisValue).replace('-实线', '')] || ''
                                    return `<div style="width: 500px;white-space: normal;font-size:12px;">${item.marker}${item.name} ${item.seriesName}：${item.value}
<br><span style="color:#3251BB;font-size: 11px">${drugsOrigin}</span></div>`
                                })
                                .join('<br>')
                        },
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xData,
                    }, ],
                    yAxis: [{
                        type: 'value',
                        axisTick: { show: false },
                        axisLine: { show: false },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        },

                    }, ],
                    series,
                })
            }
        },
    }
}
</script>